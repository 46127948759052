<script setup>
const emit = defineEmits(['close'])
let jqueryScript, paymentScript, emailScript, paddleScript
const { t } = useI18n()
const router = useRouter()
const toast = useToast()
const plan = ref('')
const user_email = ref('')
const user_name = ref('')
const user_idx = ref('')

const plan_response = await get_plan()
const user_response = await select_user('name, email, idx')
user_email.value = user_response.data.email
user_idx.value = user_response.data.idx.toString()
user_name.value = user_response.data.name

onMounted(() => {
  channelTalk.shutdown()
})

onBeforeUnmount(() => {
  channelTalk.boot({
    pluginKey: '54dbd26e-1f19-449d-baa4-3e75ae8a30f9',
    memberId: user_email.value,
    profile: {
      email: user_email.value,
    },
  })
})

if (plan_response.error)
  toast.add({ title: t('에러.플랜불러오기실패'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
else
  initPlan(plan_response.data)
async function initPlan(planName) {
  plan.value = planName

  if (plan.value === 'pro') {
    toast.add({ title: t('에러.결제창실패'), description: t('에러.이미프로플랜이어서결제창은나타나지않습니다'), color: 'red', timeout: 2000 })
    emit('close')
    router.push('/dashboard')
  }
}

const payCurrencies = [
`${t('업그레이드플랜.한국원')} (₩)`,
`${t('업그레이드플랜.미국달러')} (USD)`,
]
const { locale } = useI18n()
const payCurrency = ref(locale.value === 'ko' ? payCurrencies[0] : payCurrencies[1])

const finalPrice = computed(() => {
  if (payCurrency.value.includes('USD'))
    return 'US $15'

  else if (payCurrency.value.includes('₩'))
    return '19,900₩'
})

async function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(script) // 스크립트 요소를 반환
    }
    script.onerror = reject
    script.type = 'text/javascript'
    document.head.appendChild(script)
  })
}

function paddleOpen() {
  Paddle.Checkout.open({
    settings: {
      theme: 'light',
    },
    items: [
      {
        priceId: 'pri_01he1r8m7ehnb0895sp9gr1hq7',
        quantity: 1,
      },
    ],
    customer: {
      email: user_email.value,
    },

  })
}
async function initPaddle() {
  paddleScript = await loadScript('https://cdn.paddle.com/paddle/v2/paddle.js')
  Paddle.Setup({
    token: 'live_732430d990fbaab5f2a2ccfe349', // replace with a client-side token,
    checkout: {
      settings: {
        displayMode: 'overlay',
        theme: 'light',
        locale: 'en',
        successUrl: `${window.location.protocol}//${window.location.host}/loading?type=upgrade_plan_success`,
      },
    },
    async eventCallback(data) {
      if (data.name === 'checkout.completed') {
        const user_response = await select_user()
        const { error } = await insert_payment({
          pg_company: 'paddle',
          response_data: data,
          user_idx: user_response.data.idx,
          type: 'register',
          currency: 'USD',
        })
        sendEmailSuccess()

        toast.add({ title: t('업그레이드플랜.구독결제완료'), color: 'green', timeout: 2000 })
      }
      else if (data.name === 'checkout.error') {
        const user_response = await select_user()
        const { error } = await insert_payment({
          pg_company: 'paddle',
          response_data: data,
          user_idx: user_response.data.idx,
          type: 'register',
          currency: 'USD',
        })
        toast.add({ title: t('에러.결제창실패'), description: data.error.detail, color: 'red', timeout: 2000 })
      }
    },
  })
}

function removeScript(scriptElement) {
  // 스크립트 요소를 head에서 제거
  if (scriptElement.parentNode)
    scriptElement.parentNode.removeChild(scriptElement)
}

async function sendEmailSuccess() {
  // https://www.emailjs.com/
  const serviceID = 'default_service'
  const templateID = 'template_dnm68vq'

  try {
    emailScript = await loadScript('https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js')
    const formData = {
      name: user_name.value,
      email: user_email.value,
      price: finalPrice.value + payCurrency.value,
    }
    emailjs.init('_XEFzM4wULFRPbV6f')
    emailjs.send(serviceID, templateID, formData)
  }
  finally {
    if (emailScript)
      removeScript(emailScript)
  }
}

async function getResult(res) {
  // 여기에 결과를 처리하는 코드를 작성합니다.
  const user_response = await select_user()
  const { error } = await insert_payment({
    pg_company: 'payple',
    response_data: res,
    user_idx: user_response.data.idx,
    type: 'register',
    currency: 'KRW',
  })

  if (error) {
    toast.add({ title: t('에러.에러발생'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
    return
  }
  if (res.PCD_PAY_RST === 'success') {
    sendEmailSuccess()
    toast.add({ title: t('업그레이드플랜.구독결제완료'), color: 'green', timeout: 2000 })
    router.push('/loading?type=upgrade_plan_success')
  }
  else if (res.PCD_PAY_RST === 'error') {
    toast.add({ title: t('에러.에러발생'), description: t('에러.반복발생시이메일로문의해주세요'), color: 'red', timeout: 2000 })
  }
}

async function requestPayplePay() {
  // obj에 결제요청 파라미터 저장
  const obj = {}

  /**
   * 결제수단 선택
   * - card: 카드 | transfer: 계좌
   */
  obj.PCD_PAY_TYPE = 'card'

  /**
   * 결제요청 방식
   * - AUTH : 결제수단 등록만 요청하는 경우
   * - PAY : 등록과 결제를 동시에 요청하는 경우 (첫 결제하면서 등록도 동시에 진행)
   */
  obj.PCD_PAY_WORK = 'PAY'

  /**
   * 01 : 페이플 간편결제
   */
  obj.PCD_CARD_VER = '01'

  /**
   * 결제 상품명
   */
  obj.PCD_PAY_GOODS = 'MangoPicker Pro Plan'

  /**
   * 결제 요청금액
   */
  obj.PCD_PAY_TOTAL = '19900'

  /**
   * 결제결과 반환URL
   */
  obj.PCD_RST_URL = '/dashboard'

  /**
   * 망고피커에서 관리하는 유저 번호
   */
  obj.PCD_PAYER_NO = user_idx.value ?? ''

  /**
   * 결제고객 이름
   */
  obj.PCD_PAYER_NAME = user_name.value ?? ''

  /**
   * 해당 이메일 주소로 결제 안내메일이 발송됩니다.
   * - 결제창에서 이메일 주소를 미리 창에 입력하는 기능을 수행합니다
   */
  obj.PCD_PAYER_EMAIL = user_email.value ?? ''

  /**
   * 과세설정 (Default: Y 이며, 과세:Y, 복합과세:Y, 비과세: N)
   * - ISTAX : Y, TAXTOTAL: null이면 페이플에서 10% 부가세를 자동으로 적용합니다.
   */
  // obj.PCD_PAY_ISTAX =

  /**
   * ⦁ 복합과세(과세+면세) 주문건에 필요한 금액이며 이 파라미터에 지정한 값이 부가세로 설정되어서 결제됩니다.
   * - 예시) PCD_PAY_TOTAL = 1000, PCD_PAY_TAXTOTAL = 350 로 보낼경우,
   *        해당 주문건의 복합과세가 보낸 그대로인 350원으로 지정됩니다.
   * ※ 복합과세에는 공급가액이 아닌 실제 신고가 될 부가세 금액을 입력해야합니다.
   * ⦁ 과세 또는 비과세의 경우 사용하지 않습니다.
   */
  // obj.PCD_PAY_TAXTOTAL =

  /**
   * 파트너 클라이언트 키(clientKey)
   */
  obj.clientKey = 'A7463D54FE9C23E2D10F733C87983526'

  /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
  obj.callbackFunction = getResult // getResult : 콜백 함수명

  /* 중략 */

  // 결제요청 함수 호출
  PaypleCpayAuthCheck(obj)
}

onBeforeMount(async () => {
  /**
   * jqueryScript 다운로드 이후 paymentScript로 구현
   * 만약 동시에 다운로드 하게 되면 payment가 먼저 다운로드가 끝나 jquery를 사용 할 수 없어 에러 발생.
   */
  jqueryScript = await loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js')
  paymentScript = await loadScript('https://cpay.payple.kr/js/v1/payment.js')
  await initPaddle()
})

onBeforeUnmount(() => {
  if (jqueryScript)
    removeScript(jqueryScript)

  if (paymentScript)
    removeScript(paymentScript)

  if (paddleScript)
    removeScript(paddleScript)
})
</script>

<template>
  <div class="modal">
    <div class="Membership h-[525px] inline-flex items-center justify-center rounded-[10px] bg-white">
      <div
        class="Frame211 inline-flex shrink grow basis-0 flex-col items-start self-stretch justify-start gap-4 px-[33px] pb-[15px] pt-10"
      >
        <div class="Frame216 inline-flex items-start self-stretch justify-start gap-2.5 px-[30px]">
          <div class="text-[25px] font-bold text-black">
            {{ $t('업그레이드플랜.프로플랜으로업그레이드') }}
          </div>
        </div>
        <div
          class="Frame213 h-[125px] flex flex-col items-start self-stretch justify-center gap-[9px] px-[30px] py-[15px]"
        >
          <div class="text-[15px] font-normal text-black">
            {{ $t('업그레이드플랜.결제주기') }}
          </div>
          <div
            class="ControlsTextFieldFloatingLabel inline-flex items-start self-stretch justify-between gap-[204px] border border-orange-400 rounded-lg bg-white px-4 py-[13px]"
          >
            <div class="PartialsTextFieldFloatingLabel inline-flex flex-col items-start justify-start gap-1">
              <div class="TextLabel h-3 self-stretch text-xs font-normal leading-3 text-zinc-500">
                {{ $t('업그레이드플랜.월간') }}
              </div>
              <div class="Text h-4 self-stretch text-base font-normal leading-none text-neutral-950">
                {{ finalPrice }}/{{
                  $t('업그레이드플랜.월') }}
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
                stroke="#FE9526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          class="Frame214 h-[89px] flex flex-col items-start self-stretch justify-center gap-[9px] px-[30px] py-[15px]"
        >
          <div class="text-[15px] font-normal text-black">
            {{ $t('업그레이드플랜.통화') }}
          </div>
          <USelect
            v-model="payCurrency" class="w-full" select-class="ring-gray-300 focus:ring-1 w-full h-[33px]"
            :options="payCurrencies"
          />
          <div class="text-[13px] font-normal leading-none text-neutral-500">
            {{ $t('업그레이드플랜.업그레이드후통화변경이불가능합니다') }}
          </div>
        </div>
        <div class="Frame215 inline-flex flex-col self-stretch justify-start gap-2 px-[30px] py-3">
          <div class="inline-flex items-center justify-start gap-1">
            <div v-if="$i18n.locale === 'en'" class="w-[px] text-base font-normal leading-none text-black">
              {{
                $t('업그레이드플랜.이청구됩니다') }}
            </div>
            <div class="Us20 text-base font-bold leading-none text-black">
              {{ finalPrice }}/{{ $t('업그레이드플랜.월') }}
            </div>
            <div v-if="$i18n.locale === 'kr'" class="w-[px] text-base font-normal leading-none text-black">
              {{
                $t('업그레이드플랜.이청구됩니다') }}
            </div>
          </div>
          <div class="text-[10px] font-normal leading-none text-neutral-500">
            {{ $t('업그레이드플랜.해당세금은결제시계산됩니다') }}
          </div>
        </div>

        <button
          v-if="payCurrency.includes('₩')"
          class="Frame217 h-12 flex flex-col items-start self-stretch justify-center gap-2.5 px-[30px]" @click="requestPayplePay"
        >
          <div
            class="ControlsButtons h-12 w-[463px] inline-flex items-center justify-center rounded-[48px] bg-orange-400 py-4"
          >
            <div class="Text text-center text-base font-bold leading-none text-white">
              {{ $t('업그레이드플랜.결제계속진행') }}
            </div>
          </div>
        </button>
        <button
          v-else-if="payCurrency.includes('USD')"
          class="h-12 flex flex-col items-start self-stretch justify-center gap-2.5 px-[30px]"
          @click="paddleOpen"
        >
          <div
            class="ControlsButtons h-12 w-[463px] inline-flex items-center justify-center rounded-[48px] bg-orange-400 py-4"
          >
            <div class="Text text-center text-base font-bold leading-none text-white">
              {{ $t('업그레이드플랜.결제계속진행') }}
            </div>
          </div>
        </button>
      </div>
      <div
        class="Frame212 inline-flex flex-col items-end self-stretch justify-start gap-2.5 border-l border-zinc-200 px-[17px] py-3"
      >
        <button id="close" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M16.5669 3.93306C16.811 4.17714 16.811 4.57286 16.5669 4.81694L5.31694 16.0669C5.07286 16.311 4.67714 16.311 4.43306 16.0669C4.18898 15.8229 4.18898 15.4271 4.43306 15.1831L15.6831 3.93306C15.9271 3.68898 16.3229 3.68898 16.5669 3.93306Z"
              fill="#331E08"
            />
            <path
              fill-rule="evenodd" clip-rule="evenodd"
              d="M4.43306 3.93306C4.67714 3.68898 5.07286 3.68898 5.31694 3.93306L16.5669 15.1831C16.811 15.4271 16.811 15.8229 16.5669 16.0669C16.3229 16.311 15.9271 16.311 15.6831 16.0669L4.43306 4.81694C4.18898 4.57286 4.18898 4.17714 4.43306 3.93306Z"
              fill="#331E08"
            />
          </svg>
        </button>
        <div class="Frame219 inline-flex items-center self-stretch justify-start gap-2.5 py-[13px]">
          <div class="text-[25px] font-bold leading-none text-black">
            {{ $t('업그레이드플랜.프로플랜') }}
          </div>
        </div>
        <div class="Frame222 inline-flex items-center self-stretch justify-start gap-2.5">
          <div class="text-[13px] font-normal leading-none text-neutral-500">
            {{ $t('업그레이드플랜.아래의혜택을즉시사용하세요') }}
          </div>
        </div>
        <div class="Frame226 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.무제한웹자동화') }}
          </div>
        </div>
        <div class="Frame220 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.무제한스크래핑컬럼') }}
          </div>
        </div>
        <div class="Frame221 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.무제한블럭') }}
          </div>
        </div>
        <div class="Frame223 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.녹화블럭') }}
          </div>
        </div>
        <div class="Frame224 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="Excel text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.Excel내보내기') }}
          </div>
        </div>
        <div class="Frame225 inline-flex items-center self-stretch justify-start gap-2.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M20.5 7.4502L10.9142 17.036C10.1332 17.817 8.86683 17.817 8.08579 17.036L4.5 13.4502"
              stroke="#159303" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
          <div class="text-[13px] font-normal leading-none text-black">
            {{ $t('업그레이드플랜.스케줄링준비중') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: Pretendard, 'Noto Sans KR', 'sans-serif' !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99998;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}

.Frame212 {
  border-left: 1px solid #E4E2E7;
}

.ControlsTextFieldFloatingLabel {
  border-radius: 8px;
  border: 2px solid #FE9526;
}
</style>
